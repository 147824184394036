import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import Layout from "../components/Layout";

import Testimonials from "../components/Testimonials";

import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";

import DisplayHeroVideoOrImage from "../components/DisplayHeroVideoOrImage";
import DisplayContent from "../components/DisplayContent";
import DisplayCTATable from "../components/DisplayCTATable";

// eslint-disable-next-line
export const TestimonialsPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  videos,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  summary,
  helmet,
  contact,
  previewContent,
  testimonials,
  callToAction,
  layout,
}) => {
  let templateName = "testimonials",
    heroVideo,
    heroImage,
    footerImage;

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
  }

  if (videos && videos.hero) heroVideo = videos.hero;

  if (!layout.position) layout.position = "below";

  const showTitlesSwapped = options.showTitlesSwapped;
  const showTitle = options.showTitle;

  let showDisplayContentHeader = true;
  if (
    !options.showDisplayContentHeader &&
    options.showDisplayContentHeader !== undefined &&
    options.showDisplayContentHeader !== null
  ) {
    showDisplayContentHeader = false;
  }

  return (
    <div>
      {helmet || null}

      <DisplayHeroVideoOrImage
        heroVideo={heroVideo}
        heroImage={heroImage}
        title={title}
        subtitle={subtitle}
        imgPosition="center"
        showTitle={showTitle}
        showTitlesSwapped={showTitlesSwapped}
        header={true}
      />

      <section className={`section section--gradient ${templateName}--page`}>
        <div className="container">
          <div className="content">
            <div
              className={`content mb-4 pb-0 ${
                showDisplayContentHeader ? null : "is-hidden"
              }`}
            >
              <DisplayContentHeader
                title={title}
                breadcrumbs={breadcrumbs}
                showColon={true}
              />
            </div>

            <DisplayContent
              templateName={templateName}
              previewContent={previewContent}
              body={body}
              layout={layout}
              element={
                <>
                  {testimonials && testimonials.length ? (
                    <>
                      {layout.position === "below" ? <br clear="all" /> : <></>}
                      <div className="content testimonials">
                        <h3 className="has-text-left">Testimonials</h3>
                      </div>
                      <Testimonials testimonials={testimonials} />
                    </>
                  ) : null}

                  <br />
                  <DisplayCTATable
                    cta={callToAction}
                    columns={layout.columns}
                  />
                </>
              }
            />
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text footer-image"
          showTitle={true}
          header={false}
        />
      ) : null}
    </div>
  );
};

TestimonialsPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  videos: PropTypes.shape({
    hero: PropTypes.string,
  }),
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
  testimonials: PropTypes.array,
};

const TestimonialsPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { config, cta } = data;

  let callToAction = cta.frontmatter.callToAction;

  if (!config.frontmatter.active) {
    data.frontmatter.testimonials = [];
  }
  let layout = config.frontmatter.layout;

  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  return (
    <Layout>
      <TestimonialsPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        videos={post.frontmatter.videos}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        layout={layout}
        testimonials={config.frontmatter.testimonials}
        callToAction={callToAction}
      />
    </Layout>
  );
};

TestimonialsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TestimonialsPage;
// b558fdc5-e049-5f19-aaf7-1d9704af5a03
export const TestimonialsPageQuery = graphql`
  query TestimonialsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        summary
        videos {
          hero
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
          showDisplayContentHeader
          showTitlesSwapped
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "testimonials" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        active
        testimonials {
          quote
          author
        }
        layout {
          position
          columns
          separator
        }
      }
    }
    cta: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "call-to-action" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        callToAction {
          title
          description
          detail
          price
          slug
          icon
        }
        layout {
          position
          columns
          separator
        }
      }
    }
  }
`;
