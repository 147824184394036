import * as React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Link } from "gatsby";
const DisplayCTATable = ({ cta, columns = 0 }) => {
  let cnta = 0,
    columnClass = "",
    columnSize = "";

  if (columns !== 0) {
    columnSize = 12 / columns;
    columnClass = "is-" + columnSize;
  }

  return (
    <React.Fragment key={v4()}>
      <div className="pl-2 pr-4">
        <div className="cta-table columns is-multiline">
          {cta.map((entry) => {
            cnta++;
            return (
              <React.Fragment key={v4()}>
                {CreateCTADiv(
                  entry.title,
                  entry.description,
                  entry.detail,
                  entry.price,
                  entry.slug,
                  entry.icon,
                  cnta,
                  columnClass
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

DisplayCTATable.propTypes = {
  cta: PropTypes.array,
};
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export default DisplayCTATable;

function CreateCTADiv(
  title,
  description = "",
  detail = "",
  price = "",
  slug = "",
  icon = "",
  count = 1,
  columnClass = ""
) {
  return (
    <>
      <div className={`cta-column column color-${count} ${columnClass}`}>
        {slug ? (
          <Link to={slug}>
            <h3 className="title is-size-3 cta-title has-text-white has-text-weight-bold">
              {title}
            </h3>

            {description ? (
              <p className="has-text-white is-size-5 has-text-weight-semibold cta-description">
                {description}
              </p>
            ) : null}
            {detail ? (
              <p className="has-text-white is-size-6 has-text-weight-normal cta-detail">
                {detail}
              </p>
            ) : null}
            {price ? (
              <div className="price">
                <h4 className="title is-size-5 has-text-weight-semibold has-text-right cta-price">
                  {formatter.format(price)}
                </h4>
              </div>
            ) : null}

            <h4 className="has-text-white">
              <img src="/img/arrow-32-16.png" /> <strong>Click Here</strong>
            </h4>
          </Link>
        ) : (
          <>
            <h3 className="title is-size-3 cta-title has-text-white has-text-weight-bold">
              {title}
            </h3>

            {description ? (
              <p className="has-text-white is-size-5 has-text-weight-semibold cta-subtitle">
                {description}
              </p>
            ) : null}
            {detail ? (
              <p className="has-text-white is-size-6 has-text-weight-normal cta-detail">
                {detail}
              </p>
            ) : null}
            {price ? (
              <div className="price">
                <h4 className="title is-size-5 has-text-weight-semibold has-text-right cta-price">
                  {formatter.format(price)}
                </h4>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}
