import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

export default function Testimonials(props) {
  const {
    testimonials,
    showSlider = true,
    slideCount = 10,
    slideMode = "random",
    slideFeatured = false,
  } = props;

  let slideTestimonials = [...testimonials];

  if (showSlider && slideTestimonials.length) {
    // filter templates down to featured

    if (slideFeatured) {
      slideTestimonials = slideTestimonials.filter(
        (testimonial) => testimonial.featured === true
      );
    }

    // randomize testimonials

    if (slideMode === "random") {
      slideTestimonials = shuffle(slideTestimonials);
    }
    if (slideCount) {
      slideTestimonials = slideTestimonials.slice(0, slideCount);
    }
  }

  return (
    <>
      {showSlider && slideTestimonials.length ? (
        <div className="testimonial-carousel">
          <div className="content">
            <Carousel
              showArrows={false}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={6100}
            >
              {slideTestimonials.map((testimonial) => {
                return (
                  <div key={nanoid()}>
                    <div className="content">
                      {testimonial.image ? (
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: testimonial.image,
                            alt: testimonial.author,
                            className: "testimonials-image",
                          }}
                        />
                      ) : null}
                      <p className="has-text-left testimonial">
                        "{testimonial.quote}"
                      </p>
                      <p className="has-text-left testimonial-author">
                        <em>&mdash; {testimonial.author}</em>
                      </p>
                    </div>
                    <br clear="all" />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      ) : null}
    </>
  );
}

Testimonials.propTypes = {
  testimonials: PropTypes.array,
  showSlider: PropTypes.bool,
  slideCount: PropTypes.number,
  slideMode: PropTypes.string,
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
